// import store from '@/store'

// eslint-disable-next-line no-underscore-dangle
// console.log(store.getters['app/realmApp'].currentUser.customData._id)
export default [
  {
    header: 'Aplicaciones Disponibles',
    resource: 'rayos',
    action: 'read',
  },
  // {
  //   title: 'Email',
  //   route: 'apps-email',
  //   icon: 'MailIcon',
  // },
  // {
  //   title: 'Rayo',
  //   route: 'apps-rayo',
  //   icon: 'MailIcon',
  // },
  // {
  //   title: 'Chat',
  //   route: 'apps-chat',
  //   icon: 'MessageSquareIcon',
  // },
  // {
  //   title: 'Todo',
  //   route: 'apps-todo',
  //   icon: 'CheckSquareIcon',
  // },
  // {
  //   title: 'Calendar',
  //   route: 'apps-calendar',
  //   icon: 'CalendarIcon',
  // },
  {
    title: 'Invoice',
    icon: 'FileTextIcon',
    resource: 'rayos',
    action: 'read',
    children: [
      {
        title: 'List',
        route: 'apps-invoice-list',
        resource: 'rayos',
        action: 'read',
      },
      {
        title: 'Preview',
        route: { name: 'apps-invoice-preview', params: { id: 4987 } },
        resource: 'rayos',
        action: 'read',
      },
      {
        title: 'Edit',
        route: { name: 'apps-invoice-edit', params: { id: 4987 } },
      },
      {
        title: 'Add',
        route: { name: 'apps-invoice-add' },
      },
    ],
  },
  // {
  //   title: 'eCommerce',
  //   icon: 'ShoppingCartIcon',
  //   children: [
  //     {
  //       title: 'Shop',
  //       route: 'apps-e-commerce-shop',
  //     },
  //     {
  //       title: 'Details',
  //       route: { name: 'apps-e-commerce-product-details', params: { slug: 'apple-watch-series-5-27' } },
  //     },
  //     {
  //       title: 'Wishlist',
  //       route: 'apps-e-commerce-wishlist',
  //     },
  //     {
  //       title: 'Checkout',
  //       route: 'apps-e-commerce-checkout',
  //     },
  //   ],
  // },
  {
    title: 'Postulaciones',
    icon: 'UserIcon',
    resource: 'postulaciones',
    action: 'read',
    children: [
      {
        title: 'Listado',
        route: 'apps-postulaciones-list',
        resource: 'postulaciones',
        action: 'read',
      },
      {
        title: 'Ver Detalles',
        route: { name: 'apps-postulaciones-view', params: { id: '61088ec27d1747520db0ffd2' } },
        resource: 'postulaciones',
        action: 'read',
      },
      // {
      //   title: 'Editar',
      //   route: { name: 'apps-postulaciones-edit', params: { id: '61088ec27d1747520db0ffd2' } },
      // },
    ],
  },
  {
    title: 'Rayos',
    icon: 'UserIcon',
    // resource: 'postulaciones',
    // action: 'read',
    children: [
      {
        title: 'Listar Rayos',
        route: 'apps-users-list',
        resource: 'postulaciones',
        action: 'read',
      },
      {
        title: 'Ver Rayos',
        route: { name: 'apps-users-view', params: { id: '5f3f03bef1bcb80012c0a815' } },
        resource: 'postulaciones',
        action: 'read',
      },
      // {
      //   title: 'Edit',
      //   route: { name: 'apps-users-edit', params: { id: '5f3f03bef1bcb80012c0a815' } },
      // },
    ],
  },
  {
    title: 'Mi Empresa',
    icon: 'UserIcon',
    resource: 'epa',
    action: 'read',
    children: [
      {
        title: 'Faq',
        route: 'pages-faq',
        resource: 'epa',
        action: 'read',
      },
      {
        title: 'Toma de Turnos',
        // eslint-disable-next-line no-underscore-dangle
        // route: { name: 'apps-epas-view', params: { id: store.getters['app/realmApp'].currentUser.customData._id } },
        route: { name: 'apps-epas-view', params: { id_partners: '60bd350c35d4cc61d5e67042' } },
        // eslint-disable-next-line no-underscore-dangle
        // route: { name: 'apps-epas-view', params: { id_partners: store.getters['app/realmApp'].currentUser.customData._id } },

        resource: 'epa',
        action: 'read',
      },
      /* {
        title: 'Epas Dashboard',
        // eslint-disable-next-line no-underscore-dangle
        route: { name: 'apps-epas-dashboard', params: { id: store.getters['app/realmApp'].currentUser.customData._id } },
        resource: 'epa',
        action: 'read',
      }, */
      {
        title: 'Listar Epas (Admin)',
        route: 'apps-users-list',
        resource: 'postulaciones',
        action: 'read',
      },
      {
        title: 'Turnos y Tipos (Admin)',
        // route: { name: 'apps-epas-view', params: { id: '60bd350c35d4cc61d5e67042' } },
        route: { name: 'apps-posts-view' },
        resource: 'posts',
        action: 'admin',
      },
      {
        title: 'Resumen de Turnos',
        // route: { name: 'apps-epas-view', params: { id: '60bd350c35d4cc61d5e67042' } },
        route: { name: 'apps-resumen-post-view' },
        resource: 'posts',
        action: 'admin',
      },

      // {
      //   title: 'Edit',
      //   route: { name: 'apps-users-edit', params: { id: '5f3f03bef1bcb80012c0a815' } },
      // },
    ],
  },
  {
    title: 'Pages',
    icon: 'FileIcon',
    children: [
      {
        title: 'Authentication',
        icon: 'CircleIcon',
        children: [
          {
            title: 'Login v1',
            route: 'auth-login-v1',
            target: '_blank',
          },
          {
            title: 'Login v2',
            route: 'auth-login-v2',
            target: '_blank',
          },
          {
            title: 'Register v1',
            route: 'auth-register-v1',
            target: '_blank',
          },
          {
            title: 'Register v2',
            route: 'auth-register-v2',
            target: '_blank',
          },
          {
            title: 'Forgot Password v1',
            route: 'auth-forgot-password-v1',
            target: '_blank',
          },
          {
            title: 'Forgot Password v2',
            route: 'auth-forgot-password-v2',
            target: '_blank',
          },
          {
            title: 'Reset Password v1',
            route: 'auth-reset-password-v1',
            target: '_blank',
          },
          {
            title: 'Reset Password v2',
            route: 'auth-reset-password-v2',
            target: '_blank',
          },
        ],
      },
      {
        title: 'Account Settings',
        route: 'pages-account-setting',
      },
      {
        title: 'Profile',
        route: 'pages-profile',
      },
      {
        title: 'Faq',
        route: 'pages-faq',
      },
      {
        title: 'Knowledge Base',
        route: 'pages-knowledge-base',
      },
      {
        title: 'Pricing',
        route: 'pages-pricing',
      },
      {
        title: 'Blog',
        children: [
          {
            title: 'List',
            route: 'pages-blog-list',
          },
          {
            title: 'Detail',
            route: { name: 'pages-blog-detail', params: { id: 1 } },
          },
          {
            title: 'Edit',
            route: { name: 'pages-blog-edit', params: { id: 1 } },
          },
        ],
      },
      {
        title: 'Mail Templates',
        children: [
          {
            title: 'Welcome',
            href: 'https://pixinvent.com/demo/vuexy-mail-template/mail-welcome.html',
          },
          {
            title: 'Reset Password',
            href: 'https://pixinvent.com/demo/vuexy-mail-template/mail-reset-password.html',
          },
          {
            title: 'Verify Email',
            href: 'https://pixinvent.com/demo/vuexy-mail-template/mail-verify-email.html',
          },
          {
            title: 'Deactivate Account',
            href: 'https://pixinvent.com/demo/vuexy-mail-template/mail-deactivate-account.html',
          },
          {
            title: 'Invoice',
            href: 'https://pixinvent.com/demo/vuexy-mail-template/mail-invoice.html',
          },
          {
            title: 'Promotional',
            href: 'https://pixinvent.com/demo/vuexy-mail-template/mail-promotional.html',
          },
        ],
      },
      {
        title: 'Miscellaneous',
        icon: 'CircleIcon',
        children: [
          {
            title: 'Coming Soon',
            route: 'misc-coming-soon',
            target: '_blank',
          },
          {
            title: 'Not Authorized',
            route: 'misc-not-authorized',
            target: '_blank',
          },
          {
            title: 'Under Maintenance',
            route: 'misc-under-maintenance',
            target: '_blank',
          },
          {
            title: 'Error',
            route: 'misc-error',
            target: '_blank',
          },
        ],
      },
    ],
  },
]
